import { NgModule } from '@angular/core';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { ActiveRoutesActions } from './store/active-routes.actions';
import { ActiveRoutesSelectors } from './store/active-routes.selectors';
import { appEffects } from './store/app-store.effects';
import { appReducer } from './store/app-store.reducer';
import { debugReducer } from './store/debug.reducer';
import { patientResetReducer } from './store/patient-reset.reducer';

const metaReducers: MetaReducer<any>[] = [patientResetReducer];

if (!environment.production) {
  metaReducers.push(debugReducer);
}

@NgModule({
  imports: [
    StoreModule.forRoot(appReducer, { metaReducers }),
    EffectsModule.forRoot(appEffects),
    !environment.production ? StoreDevtoolsModule.instrument({}) : [],
  ],
  providers: [ActiveRoutesActions, ActiveRoutesSelectors],
})
export class AppStoreModule {}
