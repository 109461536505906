import { findKey } from 'lodash';
import { of, pipe, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { GraphQLResponse } from '@app/core/graphql/graphql.service';

export interface ConnectionType<T> {
  nodes: T[];
}

export const mapGraphQLResponse = <T>() =>
  pipe(
    mergeMap((response: GraphQLResponse<T>) => {
      if (response.data) {
        const failedQueryKey = findKey(response.data, o => {
          const isNotSuccessful =
            typeof o === 'object' && 'success' in o && o.success === false;
          const hasErrors =
            typeof o === 'object' && 'errors' in o && Array.isArray(o.errors)
              ? o.errors.length
              : Boolean(typeof o === 'object' && 'errors' in o && o.errors);
          return isNotSuccessful || hasErrors;
        });
        if (failedQueryKey) {
          return getErrorsOrDefault(response.data[failedQueryKey]);
        } else {
          return of(response.data);
        }
      } else {
        return getErrorsOrDefault(response);
      }
    }),
  );

function getErrorsOrDefault<T>(obj: GraphQLResponse<T>) {
  if (obj.hasOwnProperty('detailedErrors')) {
    return throwError(obj.detailedErrors);
  } else if (obj.hasOwnProperty('errors')) {
    return throwError(obj.errors);
  } else {
    return of(undefined);
  }
}
